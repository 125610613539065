// @ts-nocheck
import { OrderPlacementConfig } from '@/__configs/config_types';
import { TitleEntity, Version } from '@/redux/slices/slice_types';
import styled from '@emotion/styled';
import { Divider, Grid, IconButton, Typography } from '@tmt-insights/oms_fe_core/material';
import { LabelValue, PolarisIcon, Thumbnail, TitleVersionChip, TitleTypeLabel } from '@tmt-insights/oms_fe_core/columns';

const getLineageMetaTitle = (title: TitleEntity | {} = {}) => {
  if (!(title as TitleEntity).lineage_meta || !Object.keys((title as TitleEntity).lineage_meta).length) return '';
  const meta = (title as TitleEntity).lineage_meta;
  const season_sequence_order = meta.season_sequence_order;
  const isEpisode =
    ['program', 'episode'].includes((title as TitleEntity).og_entity_type || (title as TitleEntity).entity_type) ||
    meta.program_sequence_order ||
    meta.episode_name;
  const isMovie = meta.movie_name;
  const movieName = meta.movie_name;
  const isOVA = meta.ova_name;
  const ovaName = meta.ova_name;
  const epNum = meta.program_sequence_order || (title as TitleEntity).sequence_order || meta.episode_sequence_order;
  const epNumDisplay = isEpisode && epNum ? `E${epNum}` : '';
  const episodeName = meta.episode_name || meta.program_name || (title as TitleEntity).name;
  const seriesName = meta.series_name || '';
  const seasonNum = season_sequence_order ? `S${season_sequence_order}${epNumDisplay ? ' ' : ''}` : '';

  if (isEpisode) {
    return `${seriesName} ${seasonNum}${epNumDisplay}${(seasonNum || epNumDisplay) && ':'} ${episodeName}`;
  } else if (isMovie) {
    return `${seriesName ? seriesName + ' ' : ''}${movieName}`;
  } else if (isOVA) {
    return `${seriesName ? seriesName + ' ' : ''}${ovaName}`;
  } else {
    return `${seasonNum}${epNumDisplay}${seasonNum || epNumDisplay ? ':' : ''} ${seriesName}`;
  }
};

const orderPlacementConfig: OrderPlacementConfig = {
  entityTypes: ['series', 'movie', 'ova', 'episode'],
  titleSort: { order_on: 'alt_external_id', direction: 'asc' },
  versionOptionLabel: (version: Version) => {
    return `${version.name}: ${version.alpha_id}`;
  },
  versionDropdownOption: (version: Version) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          columnGap: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          '& .link': { opacity: 0, transition: 'opacity 0.2s ease-in-out' },
          '&:hover .link': { opacity: 1 }
        }}
      >
        <span>{`${version.name}: ${version.alpha_id}`}</span>
        <IconButton
          className="link"
          onClick={() => window.open(`${window.origin}/titles/${option.parent}/?tab=metadata&version_id=${option.id}`, '_blank')}
        >
          <PolarisIcon icon="external_link" height={18} />
        </IconButton>
      </div>
    );
  },
  searchTermFilter: (versions: Version[], searchTerm: string) => versions?.find((v) => v.alpha_id?.toLowerCase() === searchTerm.toLowerCase()),
  expandedVersionRows: (displayedVersions: Version[], searchedVersion?: Version) => {
    const VersionHeader = styled(Grid)`
      opacity: 0.5;
      .MuiTypography-root {
        font-family: 'Open Sans';
      }
    `;
    const VersionRow = styled(Grid)`
      font-family: 'Open Sans';
      min-height: 45px;
      align-items: center;
      .MuiTypography-root {
        font-family: 'Open Sans';
      }
    `;
    const VersionRows = styled.div`
      width: 100%;
      padding-left: 32px;
      padding-bottom: 10px;
    `;
    displayedVersions = displayedVersions?.sort((a, b) => a.rightsline_id.localeCompare(b.rightsline_id));
    if (searchedVersion) displayedVersions = displayedVersions?.filter((v) => v.id !== searchedVersion.id);
    return (
      <VersionRows>
        <VersionHeader container>
          <Grid item xs={4}>
            <Typography variant="caption">Alpha ID</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="caption">Version RLID</Typography>
          </Grid>
          <Grid item xs={4} ml="auto"></Grid>
        </VersionHeader>
        {!!searchedVersion && (
          <>
            <VersionRow
              key={searchedVersion.id}
              container
              sx={{ '.link': { opacity: 0, transition: 'opacity 0.2s ease-in-out' }, '&:hover .link': { opacity: 1 } }}
            >
              <Grid item xs={4}>
                <Typography>{searchedVersion?.alpha_id || searchedVersion?.meta?.characteristics?.alpha_id}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', columnGap: 0.5, alignItems: 'center' }}>
                <Typography>{searchedVersion?.rightsline_id}</Typography>
                <IconButton
                  onClick={() =>
                    window.open(`${window.origin}/titles/${searchedVersion.parent}/?tab=metadata&version_id=${searchedVersion.id}`, '_blank')
                  }
                  className="link"
                >
                  <PolarisIcon icon="external_link" height={18} style={{ color: window.theme.palette.text.primary }} />
                </IconButton>
              </Grid>
              <Grid item xs={4} ml="auto" sx={{ textAlign: 'right' }}>
                <TitleVersionChip key={searchedVersion.id} version={searchedVersion} />
              </Grid>
            </VersionRow>
            <Divider sx={{ borderColor: window.theme.palette.text.primary, mt: '1em', mb: '1em', opacity: 0.25 }} />
          </>
        )}
        {displayedVersions?.map((version) => (
          <VersionRow
            key={version.id}
            container
            sx={{ '.link': { opacity: 0, transition: 'opacity 0.2s ease-in-out' }, '&:hover .link': { opacity: 1 } }}
          >
            <Grid item xs={4}>
              <Typography>{version?.alpha_id || version?.meta?.characteristics?.alpha_id}</Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', flexDirection: 'row', columnGap: 0.5, alignItems: 'center' }}>
              <Typography>{version?.rightsline_id}</Typography>
              <IconButton
                onClick={() => window.open(`${window.origin}/titles/${version.parent}/?tab=metadata&version_id=${version.id}`, '_blank')}
                className="link"
              >
                <PolarisIcon icon="external_link" height={18} style={{ color: window.theme.palette.text.primary }} />
              </IconButton>
            </Grid>
            <Grid item xs={4} ml="auto" sx={{ textAlign: 'right' }}>
              <TitleVersionChip key={version.id} version={version} />
            </Grid>
          </VersionRow>
        ))}
      </VersionRows>
    );
  },
  componentized: {
    viewingTitle: (title: TitleEntity) => {
      const { name, meta } = title;
      const aka = meta?.characteristics?.aka?.join(', ');
      return (
        <>
          <Typography variant="h6" sx={{ mb: 0 }}>
            <strong>{name}</strong>
          </Typography>
          <div>
            {name?.toLowerCase() !== aka?.toLowerCase() && (
              <LabelValue label="AKA" horizontal>
                {aka}
              </LabelValue>
            )}
          </div>
        </>
      );
    }
    // outputHeader: (group, versionKeys) => {
    //   const outputVersion = group.title_inputs?.output_version;
    //   const editType = group.title_inputs?.edit_type?.toUpperCase();
    //   const outputName = versionKeys?.[outputVersion?.id];
    //   const pieceId = outputVersion?.id;
    //   const series = outputVersion?.lineage_meta?.series_name;
    //   const seasonNum = outputVersion?.lineage_meta?.season_sequence_order;
    //   const episodeNum = outputVersion?.sequence_order;

    //   if (!outputVersion && !pieceId && !series) return null;
    //   const bullet = (
    //     <span style={{ padding: '0 8px', opacity: 0.5, fontSize: '1rem' }}>&bull;</span>
    //   );
    //   return (
    //     <Grid container alignItems="center">
    //       <Typography sx={{ color: 'primary.main', fontWeight: 'bold' }}>{editType}</Typography>
    //       {bullet}
    //       <strong>{series}</strong>
    //       {seasonNum || episodeNum ? bullet : ''}
    //       <span style={{ opacity: 0.7 }}>
    //         {seasonNum ? `S${seasonNum} ` : ''}
    //         {episodeNum ? `E${episodeNum}:` : ''}
    //       </span>
    //       {bullet}
    //       <strong>{outputName}</strong>{' '}
    //       <span style={{ opacity: 0.7, paddingLeft: 4 }}>[{outputVersion.id}]</span>
    //     </Grid>
    //   );
    // }
  },
  fileSelectionTitle: () => {},
  versionChipTooltip: (version: Version) => `Alpha: ${version?.alpha_id || 'None'}`,
  parseVersionsFn: (versions: Version[]) =>
    versions.map((version) => ({
      ...version,
      // remove last item in array because it comes in as brand, this fixes
      // the hierarchy in the "selected versions" section on op step 2
      lineage: version.lineage.slice(0, -1)
    })),
  getLineageMetaTitle,
  titleSearchResult: (result: TitleEntity) => {
    const { name, entity_type: entityType } = result;
    return (
      <Grid container>
        <Thumbnail src={result?.thumbnail_link} />
        <Grid item sx={{ ml: 1.5 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '1.1rem', mb: 0 }}>{name}</Typography>
          <Typography variant="caption" sx={{ lineHeight: 1.9, fontSize: '.9rem' }}>
            {getLineageMetaTitle(result)}
          </Typography>
        </Grid>
        <Grid item sx={{ ml: 'auto', textAlign: 'right' }}>
          <TitleTypeLabel width="auto" type={entityType} />
        </Grid>
      </Grid>
    );
  },
  selectedVersionTitleDisplay: (displayEpisodeNum: boolean, child: TitleEntity, version: Version, versionKey: string) => {
    return (
      <Grid container alignItems="center" sx={{ py: 0.5, flexWrap: 'nowrap', maxWidth: '100%' }}>
        {displayEpisodeNum && (
          <Grid item>
            <span style={{ opacity: 0.6, paddingRight: 5 }}>E{!isNaN(child.sequence_order) ? Number(child.sequence_order) : '?'}:</span>
          </Grid>
        )}
        <Grid item flex="auto" minWidth="0">
          <Typography sx={{ maxWidth: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{child.name}</Typography>
        </Grid>
        <Grid item sx={{ paddingLeft: '5px' }}>
          <Typography>{version?.alpha_id}</Typography>
        </Grid>
        <Grid item minWidth="110px" textAlign="right">
          <TitleVersionChip key={versionKey} version={version} size="small" withTooltip />
        </Grid>
      </Grid>
    );
  },
  shouldHideHiddenProfiles: true
};

export default orderPlacementConfig;
