import React from 'react';
import { DashboardConfig } from '@/__configs/config_types';

const dashboardConfig: DashboardConfig = {
  statusCards: [
    {
      name: 'Awaiting Assets',
      status: 'awaiting_assets',
      color: 'default',
      filter: ['awaiting_assets'],
      icon: 'clock',
      excludeStatus: ['cancelled']
    },
    {
      name: 'Processing',
      status: 'processing',
      color: 'pending',
      filter: [
        'processing',
        'transcoding',
        'prepare_transcode',
        'preparing_for_edit',
        'processing_master_proxy',
        'processing_proxy',
        'sv2_processing',
        'edit'
      ],
      icon: 'timelapse',
      excludeStatus: ['cancelled']
    },
    {
      name: 'Awaiting Delivery',
      status: 'delivering',
      color: 'info',
      filter: ['delivering'],
      icon: 'flight',
      excludeStatus: ['cancelled']
    },
    {
      name: 'Delivered',
      status: 'completed',
      color: 'success',
      ignoreOnPieChart: true,
      filter: ['completed'],
      icon: 'check'
    }
  ],
  graphList: [
    {
      id: 'open-job-status',
      checked: true
    },
    {
      id: 'completed-jobs',
      checked: true
    },
    {
      id: 'recent-tasks',
      checked: true
    },
    {
      id: 'daily-reports',
      checked: true
    }
  ],
  graphSettings: {
    default: ['open-job-status', 'on-time-delivery', 'average-times', 'daily-reports'],
    'on-time-delivery': {
      datasets: {
        otd100: 'On-Time Delivery'
      },
      colors: ['primary', 'secondary']
    },
    'completed-jobs': {
      displayed_job_types: [],
      functional_displayed_job_types: (totalJobsCompletedByType) => {
        let jobCounts: { [v: string]: number } = {};
        Object.keys(totalJobsCompletedByType).forEach((date) => {
          totalJobsCompletedByType[date].forEach((jobItem) => {
            if (jobItem.job_type in jobCounts) return (jobCounts[jobItem.job_type] += jobItem.count);
            return (jobCounts[jobItem.job_type] = jobItem.count);
          });
        });
        const jobCountsArray = Object.keys(jobCounts)
          .map((type) => ({ type: type, count: jobCounts[type] }))
          .filter((type) => type.count > 0)
          .sort((a, b) => b.count - a.count);

        return jobCountsArray.map((v) => v.type).slice(0, 4);
      }
    }
  },
  maximumGraphsPerRow: 3,
  customizeDashboard: false,
  chartColors: ['#BC13FE', '#FF1493', 'info', '#39FF14'],
  maximumDailyReports: 3
};

export default dashboardConfig;
